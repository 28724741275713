import Api from '@/services/Api'

const resource = '/review'

export default {

  getReviews (type, pid) {
    return Api().get(`${resource}/${type}/${pid}`)
  },
  putReview (credentials) {
    return Api().post(`${resource}/put`, credentials)
  }

}
