<template>
  <div>
    <div>
      <h4>Review | {{reviews.length}} Reviews</h4>
    </div>
    <article class="uk-comment uk-comment-primary uk-padding-remove uk-margin-medium-bottom">
      <header class="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>
        <div :class="{'uk-animation-shake': animated}">
          <star-rating
            :size='1'
            :color='color'
            v-model="apprate"
            :maxScore='5'
            :readOnly='false'
            @rate='rate'
          ></star-rating>
        </div>
        <div class="uk-width-expand">
          <h4 class="uk-comment-title uk-margin-remove uk-text-right">Name: {{this.$store.getters.currentUser.firstName}}</h4>
        </div>
      </header>
      <form @submit.prevent="sendreview">
        <div class="uk-margin">
          <textarea
            v-model="enterreview"
            class="uk-textarea"
            rows="4"
            placeholder="Enter your review"
          ></textarea>
        </div>
        <div>
          <p class="uk-text-right">
            <button type="submit" class="uk-button uk-button-primary">Send</button>
          </p>
        </div>
      </form>
    </article>
    <hr>

    <ul class="uk-list uk-list-divider">
      <li v-for="(item, index) in reviews" :key="index">
        <article class="uk-comment uk-comment-primary uk-padding-small">
          <header class="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>
            <div class="uk-text-right">
              <star-rating
                :size='1'
                :score="item.rate"
                :color='color'
                :maxScore='5'
                :readOnly='true'
              ></star-rating>

            </div>
            <div class="uk-width-expand">
              <h4 class="uk-comment-title uk-margin-remove">{{item.user.name}}</h4>
            </div>
            <div class="uk-width-expand uk-text-right">{{item.createdAt | moment}}</div>
          </header>
          <div class="uk-comment-body">
            <p class="uk-text-justify">{{item.body}}</p>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>


<script>
  import ReviewService from '@/services/ReviewService'
  import moment from 'moment'
  import starRating from "vue-star-rate";

  export default {
    props: ['type', 'id'],
    components: {
      starRating
    },
    data() {
      return {
        reviews: [],
        apprate: 0,
        enterreview: '',
        color: '#0281b8',
        animated: false


      }
    },
    async mounted() {
      // console.log(this.type)
      this.loadReviews()
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY-MM-DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    methods: {
      rate(val) {
        console.log(val)
        this.color = "#0281b8"
      },

      async sendreview() {
        try {
          if (this.apprate > 0 && this.enterreview.length > 0) {
            const response = await ReviewService.putReview({
              body: this.enterreview,
              rate: this.apprate,
              productId: this.id,
              pType: this.type
            })

            console.log(response.data)
            if (response.data.success) {
              this.enterreview = ''
              this.apprate = 0
              this.loadReviews()
            }
          } else {
            if (this.apprate == 0) {
              var self = this;
              self.animated = true;
              setTimeout(function () {
                self.animated = false;
              }, 1000);
            }
            if (this.enterreview.length == 0){
              alert("please enter review")
            }
          }
        } catch (error) {
          this.error = error.response.data.error
        }
      },
      loadReviews() {
        if (this.id) {
          ReviewService.getReviews(this.type, this.id).then(res => {
            const {data} = res
            this.reviews = data
            console.log(data)
            if (!this.reviews.length) {
              return
            }

            this.$emit('dataa', {reviews: this.reviews})
          })
        }
      }
    }
  }
</script>

<style scoped>
</style>
